// utils
import type _AddressManager from 'mobx/AddressManager'
import type _Infra from 'mobx/Infra'
import type _Account from 'mobx/Account'
import type _User from 'mobx/User'
import type _Home from 'mobx/Home'
import type { Theme as MuiTheme } from '@material-ui/core/styles/createTheme'
import type { Brand } from 'utils/theme/GlobalStyle'
import { sendRequest, getDomainByEnv, hideOneTrustLogo, initGlobalErrorHandler, overrideConsoleError, isHeadlessBrowser } from 'utils/utils'
import oStorage from 'utils/o-storage'

import versionInfo from '../../../version.json'

import { action, observable } from 'mobx'
import type { Page } from 'utils/constants'
import { CONSTANTS } from 'utils/constants'
import { initAnalytics, sendCustomEvent } from 'utils/analytics/analytics'
import { isEmpty } from 'lodash-es'
import { THEME_STRATEGY } from 'constants/theme'
import { getThemeUrl } from 'utils/theme/themeManager'
import { isParamsLocaleValid } from 'utils/validation/validation'
import ApplicationDependencies from './ApplicationDependencies'
import Cookies from 'js-cookie'
import nextCookiesState from '../../../utils/nextCookiesState'
import type { LanguageCode, LocaleObject } from 'utils/language'

const { version } = versionInfo

type AddressManager = typeof _AddressManager
type Infra = typeof _Infra
type Account = typeof _Account
type User = typeof _User
type Home = typeof _Home

interface ApplicationDependenciesInterface {
	isMobile: (skipForStaticPages?: boolean) => boolean
	isMobileApp: () => boolean
	pushHistoryState: (newUrl: string, unused: string) => void
	replaceHistoryState: () => void
}

class Application {
	dependencies: null | ApplicationDependenciesInterface = null

	constructor(dependencies: ApplicationDependenciesInterface) {
		this.dependencies = dependencies
	}

	@observable analyticsIsInited = false

	@observable isInited = false

	@observable combinedTheme: MuiTheme | null = null

	@observable isMobileApp: boolean | undefined = undefined

	@observable isMobileView: boolean | undefined = undefined

	@observable isIphoneX: boolean | undefined = undefined

	@observable isIphone: boolean | undefined = undefined

	@observable isAndroid: boolean | undefined = undefined

	@observable page: Page | undefined = undefined

	@action setIsInited = (isInited: boolean) => {
		this.isInited = isInited
	}

	@action setAnalyticsIsInited = (isInited: boolean) => {
		this.analyticsIsInited = isInited
	}

	@action setIsMobileApp = (value: boolean) => {
		this.isMobileApp = value ?? false
	}

	@action setIsMobileView = (value: boolean) => {
		this.isMobileView = value ?? false
	}

	@action setIsIphoneX = (value: boolean) => {
		this.isIphoneX = value ?? false
	}

	@action setIsIphone = (value: boolean) => {
		this.isIphone = value ?? false
	}

	@action setIsAndroid = (value: boolean) => {
		this.isAndroid = value ?? false
	}

	@action setCombinedTheme = (theme: MuiTheme) => {
		this.combinedTheme = theme
	}

	@action setPage = (page: Page): void => {
		this.page = page
	}

	/**
	 * loads dynamic font css file from S3 and sets it in the head of the document as a link
	 * this is dependant on the chain id in params json currently coming from:
	 * https://cdn.tictuk.com/<chainId>/theme/assets/fonts.css
	 */
	loadCSSFonts({
		chainId,
		brand,
		theme,
		themeStrategy,
	}: {
		themeStrategy: THEME_STRATEGY
		theme: THEME_STRATEGY
		chainId: string
		brand: Brand | undefined
	}): void {
		const href = `${getThemeUrl(theme || THEME_STRATEGY.GENERIC, chainId, brand)}/assets/fonts.css`
		/* if (themeStrategy) {
			href = `${getThemeUrl(THEME_STRATEGY.GENERIC, chainId, brand)}/assets/fonts.css`
		} */
		const sheet = document.createElement('link')
		sheet.rel = 'stylesheet'
		sheet.href = href
		sheet.type = 'text/css'
		document.head.appendChild(sheet)
	}

	/**
	 * this function loads the background image for home in parallel to help loading the page faster
	 * @param backgroundImageUrl
	 */
	preloadBackgroundImage = (backgroundImageUrl: string): void => {
		if (backgroundImageUrl) {
			const sheet = document.createElement('link')
			sheet.rel = 'preload'
			sheet.href = backgroundImageUrl
			sheet.as = 'image'
			document.head.appendChild(sheet)
		}
	}

	// Init next js app on client side rendering (CSR)
	@action
	async startNextCSRInit(
		urlQueryLanguage: LanguageCode,
		setLocale: (locale: LocaleObject) => void,
		User: User,
		Account: Account,
		Infra: Infra,
		AddressManager: AddressManager,
		isMobileApp: boolean
	) {
		const { appParams } = Infra

		// Setting locale for Infra, Home & Account mobx store
		if (appParams?.locale && isParamsLocaleValid(appParams?.locale)) {
			Infra.setLocale(appParams?.locale)
		} else {
			const localeRes = await Infra.getLocale()
			Infra.setLocale(localeRes)
		}
		setLocale(Infra.locale)
		Account.setDefaultCountry(Infra.locale.region)

		const addressLocalStorage = AddressManager.getAddressFromLocaleStorage()
		if (!isEmpty(addressLocalStorage)) {
			AddressManager.setFullAddress({ ...addressLocalStorage }, undefined, false, false)
		}

		// load the user preferredLanguage from localstorage (if there is one)
		User.init(appParams)

		// If language is present in the URL, we force the user language on it
		// ex: /en/menu, /de/menu
		if (urlQueryLanguage) {
			User.preferredLanguage = urlQueryLanguage
		}

		if (appParams.eCommerce) {
			Account.getUser()

			const preferredLanguage = User.preferredLanguage || appParams.l

			if (!isHeadlessBrowser()) {
				if (!this.analyticsIsInited) {
					await initAnalytics(appParams, this.isMobileApp)
				}
				// send JS errors to analytics
				initGlobalErrorHandler(sendCustomEvent)
				// send console.error logs to analytics
				overrideConsoleError(sendCustomEvent)
			}

			oStorage.set(CONSTANTS.USER.PREFERRED_LANGUAGE, preferredLanguage)
			hideOneTrustLogo(Infra.eCommerceFooter)

			if (appParams.assets?.homepageBackgroundDesktop && !this.isMobileView) {
				this.preloadBackgroundImage(appParams.assets?.homepageBackgroundDesktop)
			} else if (appParams.assets?.homepageBackgroundMobile && this.isMobileView) {
				this.preloadBackgroundImage(appParams.assets?.homepageBackgroundMobile)
			}

			this.endInit(Infra)
		}

		nextCookiesState.fromClient.set('forceMobileApp', isMobileApp.toString(), Cookies)
	}

	@action
	fetchAndUpdateHomePageData(Infra: Infra, Home: Home) {
		const { l: lang, c: chainId, cust = 'openRest' } = Infra.appParams

		const request = (type: string) => {
			const queryParams = `chainId=${chainId}&lang=${lang}&type=${type}&cust=openRest`
			return sendRequest(false, `${getDomainByEnv()}webFlowAddress?${queryParams}`, 'get', null, undefined, true, 90000, null)
		}

		try {
			Infra.setLoading(false)

			if (!Home.branchesList.msg && isEmpty(Home.logo)) {
				const initialRequests = [request('getBranchesList'), request('getLogo')]

				if (isEmpty(Infra.locale)) {
					initialRequests.push(Infra.getLocale())
				} else {
					Home.setLocale(Infra.locale)
				}

				// request data asynchronous to avoid waiting for the whole app to load waiting for it
				Promise.all(initialRequests).then((data) => {
					Home.setBranchesList(data[0])
					Home.setLogo(data[1])

					if (isEmpty(Infra.locale)) {
						Home.setLocale(data[2])
						Infra.setLocale(data[2])
					}
				})
			} else {
				Home.setLocale(Infra.locale)
			}
		} catch (e) {
			Infra.setLoading(false)
			Infra.setErrorNotification((e as Error).message)
		}
	}

	async sendSetChannelEvent() {
		sendCustomEvent({
			category: 'set_channel',
			action: 'set_channel',
			label: 'set_channel_pathname',
			session_channel: this.isMobileApp ? 'app' : 'web',
		})
	}

	@action
	async endInit(Infra: Infra) {
		const parsed = Infra.appParams

		if (!this.analyticsIsInited) {
			this.sendSetChannelEvent()

			sendCustomEvent({
				category: 'init',
				action: parsed.eCommerce ? 'init_ecommerce' : 'init',
				label: 'set_channel',
				session_channel: this.isMobileApp ? 'app' : 'web',
			})
			sendCustomEvent({
				category: 'init',
				action: parsed.eCommerce ? 'init_ecommerce' : 'init',
				label: parsed.eCommerce ? parsed.name : parsed.c || parsed.pc,
				webAppVersion: version,
				brand: parsed.eCommerce ? Infra.appParams.brand : 'NON-ECOMMERCE',
				platform: this.isMobileApp ? 'mobile-app' : 'web',
			})
		}

		this.setIsInited(true)
		this.setAnalyticsIsInited(true)
	}
}

export default new Application(ApplicationDependencies)
